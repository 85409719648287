import classnames from 'classnames';
import React from 'react';

import { getMovieReleaseYear } from '../../../../helper/movie-helper';
import useLocale from '../../../../hooks/useLocale';
import { MovieCopyrightDto } from '../../../../models/movie-copyright/MovieCopyrightDto.types';

import { CellContainerProps } from './CellContainer';

export function TopTenMovieListCell(props: CellContainerProps<MovieCopyrightDto>) {
    const { row, column } = props;

    const movie = row._embedded?.content?._embedded?.cineamoMovie;

    const { locale } = useLocale();

    return (
        <td className={classnames(column.columnWidth, 'py-8 px-12')}>
            <div className="flex flex-row space-x-16 h-120 overflow-hidden">
                <div className="flex flex-col flex-auto w-auto space-y-8 overflow-hidden">
                    <span
                        className="relative text-body-bold-14 h-25 text-ellipsis"
                        title={movie?.title}>
                        {movie?.title}
                    </span>
                    <span className="text-body-14">{getMovieReleaseYear(movie, locale)}</span>
                </div>
            </div>
        </td>
    );
}
