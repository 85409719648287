import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

import InfoCardSmall from '@cineamo/legacy-frontend-lib/src/components/ui/cards/InfoCardSmall';

import { MovieCopyrightListResponse } from '@cineamo/legacy-frontend-lib/src/api/ApiListResponse.types';
import { ApiResponse } from '@cineamo/legacy-frontend-lib/src/api/ApiResponse.types';

export type DistributorStatisticsPanelProps = {
    topTenMoviesResponse: ApiResponse<MovieCopyrightListResponse>;
};

function DistributorStatisticsPanel(props: DistributorStatisticsPanelProps): JSX.Element {
    const { t } = useTranslation('dashboard');

    const { topTenMoviesResponse } = props;

    return (
        <div
            className={classnames(
                'h-full hidden lg:flex flex-col w-300 justify-between lg:border-l-1 border-white border-opacity-20 transform transition-all duration-300'
            )}>
            <div className="flex flex-col p-24 space-y-24 overflow-auto">
                <InfoCardSmall
                    label={t('total-movies-on-cineamo')}
                    value={`${topTenMoviesResponse?._total_items || 0}`}
                />
                {/*//TODO:re-instigate once an endpoint exists that shows the number of movie copyrights requested as events*/}
                {/*<InfoCardSmall*/}
                {/*    label={t('movies-requested-by-this')}*/}
                {/*    value="57"*/}
                {/*/>*/}

                {/*<InfoCardSmall*/}
                {/*    label={t('completed-events')}*/}
                {/*    value="3"*/}
                {/*/>*/}
                {/*<InfoCardSmall*/}
                {/*    label={t('new-requests-last-30-days')}*/}
                {/*    value="5"*/}
                {/*/>*/}
            </div>
            <div className="p-24 space-y-16">{/*<InfoCardSmall label={t('common:links')} />*/}</div>
        </div>
    );
}

export default DistributorStatisticsPanel;
