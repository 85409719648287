import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import DashboardAnalysisModal from '@/components/modal/DashboardAnalysisModal';
import DistributorStatisticsPanel from '@/components/pages/distributor/DistributorStatisticsPanel';
import DistributorTopTenRequestedMoviesCard from '@/components/pages/distributor/DistributorTopTenRequestedMoviesCardProps';
import InfoCardSmall from '@cineamo/legacy-frontend-lib/src/components/ui/cards/InfoCardSmall';

import { getMovieCopyrights } from '@cineamo/legacy-frontend-lib/src/api/movie-copyright/movie-copyright-api';

import localeMiddleware from '@/src/middleware/localeMiddleware';
import withMiddlewareDefault from '@/src/middleware/withMiddlewareDefault';
import { parallel } from '@cineamo/legacy-frontend-lib/src/middleware/helpers';

import { useDistributorStore } from '@cineamo/legacy-frontend-lib/src/store/distributor/distributorStore';
import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

import IconAnalysis24 from '@cineamo/legacy-frontend-lib/src/icons/ic-analysis';

function IndexPage() {
    const { userType } = useUserStore();

    const { t } = useTranslation('dashboard');

    const { distributor } = useDistributorStore();

    const [showAnalysisModal, setShowAnalysisModal] = useState<boolean>(false);

    const {
        data: topTenMoviesResponse,
        isLoading: isLoadingTopTenMovies,
        isRefetching: isRefetchingTopTenMovies
    } = useQuery(
        ['movie-copyrights-top-10', distributor],
        async () => {
            if (!userType || !distributor) return null;

            return await getMovieCopyrights({
                distributorId: distributor?.id,
                isRegularShowtime: false,
                sort: 'eventRequestsCount',
                order: 'desc'
            });
        },
        {
            staleTime: 1000 * 60 * 30, // 30 Minutes
            refetchOnWindowFocus: false
        }
    );

    const movieCopyrights = topTenMoviesResponse?._embedded?.['movie-copyrights'] || [];

    return (
        <div className="flex flex-col flex-auto">
            <DashboardAnalysisModal
                modalIsOpen={showAnalysisModal}
                setModalIsOpen={setShowAnalysisModal}>
                <InfoCardSmall
                    labelClassName="text-darkBlue text-opacity-50"
                    label={t('total-movies-on-cineamo')}
                    valueClassName="text-darkBlue"
                    value={`${topTenMoviesResponse?._total_items || 0}`}
                />
                {/*//TODO:re-instigate once an endpoint exists that shows the number of movie copyrights requested as events*/}
                {/*<InfoCardSmall*/}
                {/*    labelClassName="text-darkBlue text-opacity-50"*/}
                {/*    label={t('movies-requested-by-this')}*/}
                {/*    valueClassName="text-darkBlue"*/}
                {/*    value="57"*/}
                {/*/>*/}

                {/*<InfoCardSmall*/}
                {/*    labelClassName="text-darkBlue text-opacity-50"*/}
                {/*    label={t('completed-events')}*/}
                {/*    valueClassName="text-darkBlue"*/}
                {/*    value="3"*/}
                {/*/>*/}
                {/*<InfoCardSmall*/}
                {/*    labelClassName="text-darkBlue text-opacity-50"*/}
                {/*    label={t('new-requests-last-30-days')}*/}
                {/*    valueClassName="text-darkBlue"*/}
                {/*    value="5"*/}
                {/*/>*/}
            </DashboardAnalysisModal>

            <div className="flex flex-row flex-auto h-full">
                <div className="p-24 w-full">
                    <div className="pb-16 lg:pb-0 lg:hidden flex justify-end">
                        <div
                            className="cursor-pointer"
                            onClick={() => setShowAnalysisModal(true)}>
                            <IconAnalysis24 className="text-white" />
                        </div>
                    </div>

                    <div className="space-y-24">
                        <DistributorTopTenRequestedMoviesCard
                            movieCopyRightEventRequests={movieCopyrights}
                            isLoading={!distributor || isLoadingTopTenMovies || isRefetchingTopTenMovies}
                        />

                        {/*<DistributorEventsWithYourMoviesCard />*/}
                    </div>
                </div>

                <DistributorStatisticsPanel topTenMoviesResponse={topTenMoviesResponse} />
            </div>
        </div>
    );
}

export default IndexPage;

export const getServerSideProps = withMiddlewareDefault(
    parallel(localeMiddleware(['dashboard', 'list', 'filters', 'sorting', 'common']))
);
