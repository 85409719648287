import { UrlQueryParameter, buildUrlQuery } from '../../helper/url-query-helper';
import { MovieCopyrightDto } from '../../models/movie-copyright/MovieCopyrightDto.types';
import { MovieCopyrightInputDto } from '../../models/movie-copyright/MovieCopyrightInputDto.types';
import { ApiErrorResponse } from '../ApiErrorResponse.types';
import { MovieCopyrightListResponse } from '../ApiListResponse.types';
import { ApiResponse } from '../ApiResponse.types';
import apiClient from '../cineamoApiClient';

// <------------------------ Movie Copyrights ------------------------>

type GetMovieCopyrightsQueryParameter = UrlQueryParameter & {
    cineamoMovieId?: string;
    distributorId?: string | number;
    hasMarketingAssistantBooked?: boolean;
    hasContent?: boolean;
};

/**
 * This endpoint returns a list of movie copyrights.
 *
 * @example
 * // Get the movie copyrights of a specific distributor:
 * getMovieCopyrights({distributorId: 1});
 *
 * @param params
 * @returns {ApiResponse<MovieCopyrightListResponse>}
 */
export async function getMovieCopyrights(
    params?: GetMovieCopyrightsQueryParameter
): Promise<ApiResponse<MovieCopyrightListResponse>> {
    return apiClient
        .get('/movie-copyrights' + buildUrlQuery(params))
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Movie Copyright ------------------------>

/**
 * This endpoint returns a single movie copyright.
 *
 * @example
 * // Get a movie copyright by movie copyright id:
 * getMovieCopyrightById(1);
 *
 * @param movieCopyrightId
 * @returns {ApiResponse<MovieCopyrightDto>}
 */
export async function getMovieCopyrightById(
    movieCopyrightId: number | string
): Promise<ApiResponse<MovieCopyrightDto>> {
    return apiClient
        .get(`/movie-copyrights/${movieCopyrightId}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Add Movie Copyright ------------------------>

/**
 * This endpoint adds a new movie copyright.
 *
 * @example
 * // Add a movie copyright for a specific distributor:
 * addMovieCopyright(movieCopyright, 1);
 *
 * @param movieCopyright
 * @returns {Promise<ApiResponse<MovieCopyrightDto> | ApiErrorResponse<MovieCopyrightDto>>}
 */
export async function createMovieCopyright(
    movieCopyright: MovieCopyrightInputDto
): Promise<ApiResponse<MovieCopyrightDto> | ApiErrorResponse<MovieCopyrightInputDto>> {
    return apiClient
        .post('/movie-copyrights', movieCopyright)
        .then((response) => response.data)
        .catch((error) => {
            return error;
        });
}

// <------------------------ Delete Movie Copyright ------------------------>

export async function deleteMovieCopyright(movieCopyrightId: number): Promise<boolean> {
    return apiClient
        .delete(`/movie-copyrights/${movieCopyrightId}`)
        .then((res) => res.status === 204)
        .catch(() => {
            return false;
        });
}

// <------------------------ Update Movie Copyright ------------------------>

export async function updateMovieCopyright(
    movieCopyright: MovieCopyrightInputDto,
    movieCopyrightId: number
): Promise<ApiResponse<MovieCopyrightDto> | ApiErrorResponse<MovieCopyrightInputDto>> {
    return apiClient
        .patch(`/movie-copyrights/${movieCopyrightId}`, movieCopyright)
        .then((response) => response.data)
        .catch((error) => {
            return error;
        });
}
