import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

import AdminCard from '@cineamo/legacy-frontend-lib/src/components/ui/cards/AdminCard';
import { TopTenMovieListCell } from '@cineamo/legacy-frontend-lib/src/components/ui/table/cell/TopTenMovieListCell';
import { TopTenMoviePictureCell } from '@cineamo/legacy-frontend-lib/src/components/ui/table/cell/TopTenMoviePictureCell';
import Table from '@cineamo/legacy-frontend-lib/src/components/ui/table/Table';
import { TableColumn } from '@cineamo/legacy-frontend-lib/src/components/ui/table/Table.types';

import { MovieCopyrightDto } from '@cineamo/legacy-frontend-lib/src/models/movie-copyright/MovieCopyrightDto.types';

export type DistributorTopTenRequestedMoviesCardProps = {
    movieCopyRightEventRequests: MovieCopyrightDto[];
    isLoading: boolean;
};

function DistributorTopTenRequestedMoviesCard(props: DistributorTopTenRequestedMoviesCardProps): JSX.Element {
    const { t } = useTranslation(['dashboard', 'list']);

    const { movieCopyRightEventRequests, isLoading } = props;

    const movies: (MovieCopyrightDto & { rangIndex: number })[] = movieCopyRightEventRequests?.map(
        (movieCopyRightEventRequest, index) => ({
            ...movieCopyRightEventRequest,
            rangIndex: index + 1
        })
    );

    const topTenMoviesTableHeadsLeft: TableColumn<MovieCopyrightDto>[] = [
        {
            title: '#',
            accessor: 'rangIndex',
            columnWidth: 'w-64 pl-12',
            tableColumnHeadClassName: 'px-0',
            columnClassName: 'text-headline-20 block'
        },
        {
            title: '',
            cell: TopTenMoviePictureCell,
            columnWidth: 'w-120'
        },
        {
            title: t('movie'),
            cell: TopTenMovieListCell
        },
        {
            title: t('requests'),
            columnClassName: 'text-cyan text-center text-body-bold-14 block flex justify-end whitespace-normal',
            accessor: 'eventRequestsCount',
            columnWidth: 'w-140'
        }
    ];

    const topTenMoviesTableHeadsRight: TableColumn<MovieCopyrightDto>[] = [
        {
            title: t('rank'),
            accessor: 'rangIndex',
            columnWidth: 'w-64 pl-24',
            tableColumnHeadClassName: 'px-24',
            columnClassName: 'text-headline-20 block'
        },
        {
            title: '',
            cell: TopTenMoviePictureCell,
            columnWidth: 'w-120'
        },
        {
            title: t('movie'),
            cell: TopTenMovieListCell
        },
        {
            title: t('requests'),
            columnClassName: 'text-cyan text-center text-body-bold-14 block flex justify-end whitespace-normal',
            accessor: 'eventRequestsCount',
            columnWidth: 'w-140'
        }
    ];

    return (
        <AdminCard title={t('top-ten-requested-movies')}>
            <div className={classnames('flex flex-col lg:flex-row')}>
                <Table
                    data={movies.slice(0, 5)}
                    columns={topTenMoviesTableHeadsLeft}
                    headerClassName="h-40 text-body-bold-14 tracking-wider"
                    tableClassName="hidden lg:block"
                    isLoading={isLoading}
                    useAutoHeight={false}
                    t={t}
                    transparent
                />

                {movies.length > 5 && (
                    <Table
                        data={movies.slice(5, 10)}
                        columns={topTenMoviesTableHeadsRight}
                        headerClassName="h-40 text-body-bold-14 tracking-wider"
                        tableClassName="hidden lg:block border-l-1 border-white border-opacity-20"
                        isLoading={isLoading}
                        useAutoHeight={false}
                        t={t}
                        transparent
                    />
                )}

                <Table
                    data={movies}
                    columns={topTenMoviesTableHeadsLeft}
                    headerClassName="h-40 text-body-bold-14 tracking-wider"
                    tableClassName="block lg:hidden"
                    isLoading={isLoading}
                    useAutoHeight={false}
                    t={t}
                    transparent
                />
            </div>
        </AdminCard>
    );
}

export default DistributorTopTenRequestedMoviesCard;
